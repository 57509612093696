import React, { useState, useRef, useMemo, useEffect } from "react";
import { Button } from "react-bootstrap";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { AgGridReact } from "ag-grid-react";
import { FlowNotification } from "../../../onsight-plus-ext"
import { authService } from "../../../index";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Extensions as ExtensionsApi } from "../../../onsight-plus"

export const TableBlock = (props) => {
  const gridRef = useRef();
  const [initialLoad, setInitialLoad] = useState(true);
  const initialLoadRef = useRef(true)
  initialLoadRef.current = initialLoad
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [extensionName, setExtensionName] = useState(null)
  const extensionNameRef = useRef(extensionName);
  extensionNameRef.current = extensionName;

  let styleDefinition = {
    headerAlign: "",
    traditionalStylesTitle: "",
    traditionalStylesHeader: "",
    traditionalStylesBody: "",
  }

  function LinkComponent(props) {
    let content = "";
    let url;
    try {
      url = new URL(props.value);
    } catch (ex) {
      console.warn("Invalid URL for table", props.value, ex);
      url = props.value;
    }
    content = (
      <a href={url} rel="noopener noreferrer" target="_blank">
        {props.data.hlTitle === undefined ? props.value : props.data.hlTitle}
      </a>
    );
    return content;
  }

  const CallButtonRenderer = (props) => {
    if (props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected") {
      return (
        <>
          <Button
            className={getEmailButtonClass(props.data["STATUS"]) + " me-1"}
            onClick={(e) => handleSendEmail(e, props.data)}>
            SEND
          </Button>
          <Button
            className={getEmailButtonClass(props.data["STATUS"]) + " btn-danger"}
            onClick={(e) => deleteRow(e, props.data)}>
            DEL
          </Button>
        </>
      );
    }

    return (
      <Button
        className={getEmailButtonClass(props.data["STATUS"])}
        onClick={(e) => cloneRow(e, props.data)}>
        COPY
      </Button>
    );
  };

  const openPdf = (attachment) => {
    let pdfWindow = window.open("")
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      encodeURI(attachment) + "'></iframe>"
    )
  }

  const ImageRenderer = (props) => {
    if (props.data.attachment !== undefined && props.data.attachment !== '') {
      return (<button onClick={() => openPdf(props.data.attachment)} type="button" className="btn-sm btn btn-secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
          <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
          <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"></path>
        </svg>
      </button>
      )
    } else {
      return (
        <button type="button" className="disabled btn-sm btn btn-secondary">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
            <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"></path>
          </svg>
        </button>
      )
    }
  };

  const DateRenderer = (props) => {
    const minDate = new Date('Dec 31, 1, 11:59 PM');
    const curDate = new Date(props.data.DATE);
    if (curDate < minDate) {
      return '-';
    }
    return props.data.DATE;
  }

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 100,
    }),
    []
  );

  const handleSendEmail = async (event, data) => {
    event.target.parentElement.childNodes.forEach(node => {
      node.disabled = true;
    })
    await FlowNotification.emailNotification(extensionNameRef.current, data.EMAIL_RECIPIENT, data.SUBJECT, data.requestId, data.attachment)
    .then(() => {
      FlowNotification.updateNotification(extensionNameRef.current, data.requestId, data.SUBJECT, data.EMAIL_RECIPIENT)
      .then()
      .catch(error => {
        console.log(error)
      })
    })
    .catch(error => {
      console.log(error)
      event.target.parentElement.childNodes.forEach(node => {
        node.disabled = false;
      })
    })
  };

  const cloneRow = async (event, data) => {
    event.target.parentElement.childNodes.forEach(node => {
      node.disabled = true;
    })
    await FlowNotification.addNotification(extensionNameRef.current, data.EMAIL_RECIPIENT, data.SUBJECT, data.attachment)
      .then()
      .catch(error => {
        console.log(error)
        event.target.parentElement.childNodes.forEach(node => {
          node.disabled = false;
        })
      })
  };

  const deleteRow = async (event, data) => {
    event.target.parentElement.childNodes.forEach(node => {
      node.disabled = true;
    })
    await FlowNotification.deleteNotification(extensionNameRef.current, data.requestId)
      .then()
      .catch(error => {
        console.log(error)
        event.target.parentElement.childNodes.forEach(node => {
          node.disabled = false;
        })
      })
  };

  const getEmailButtonClass = (status) => {
    let className = "btn-primary";

    switch (status) {
      case "Unsent":
        className = "btn-success";
        break;
      case "Rejected":
        className = "btn-danger";
        break;
    }

    return "btn-sm " + className;
  };

  const createTableStyles = (styleDefinition, bs) => {
    if (bs["fontColorInputTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "color:" + bs["fontColorInputTitle"] + ";";
    } else if (bs["fontSizeInputTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "font-size:" + bs["fontSizeInputTitle"] + ";";
    } else if (bs["fontFamilyInputTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "font-family:" + bs["fontFamilyInputTitle"] + ";";
    } else if (bs["alignmentTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "text-align:" + bs["alignmentTitle"] + ";";
    } else if (bs["italicTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "font-style:" + (bs["italicTitle"] === false ? "normal" : "italic") + ";";
    } else if (bs["boldTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "font-weight:" + (bs["boldTitle"] === false ? "normal" : "bold !important") + ";";
    } else if (bs["bgColorTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "background-color:" + bs["bgColorTitle"] + ";";
    } else if (bs["underlineTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "text-decoration:" + (bs["underlineTitle"] === false ? "none" : "underline") + ";";
    } else if (bs["fontColorInputHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "color:" + bs["fontColorInputHeader"] + ";";
    } else if (bs["fontSizeInputHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "font-size:" + bs["fontSizeInputHeader"] + ";";
    } else if (bs["fontFamilyInputHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "font-family:" + bs["fontFamilyInputHeader"] + ";";
    } else if (bs["alignmentHeader"] !== undefined) {
      if (bs["alignmentHeader"] === "right") {
        styleDefinition.headerAlign = "ag-right-aligned-header";
      }
    } else if (bs["italicHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "font-style:" + (bs["italicHeader"] === false ? "normal" : "italic") + ";";
    } else if (bs["boldHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "font-weight:" + (bs["boldHeader"] === false ? "normal" : "bold !important") + ";";
    } else if (bs["underlineHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "text-decoration:" + (bs["underlineHeader"] === false ? "none" : "underline") + ";";
    } else if (bs["fontColorInputBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "color:" + bs["fontColorInputBody"] + ";";
    } else if (bs["fontSizeInputBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "font-size:" + bs["fontSizeInputBody"] + ";";
    } else if (bs["fontFamilyInputBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "font-family:" + bs["fontFamilyInputBody"] + ";";
    } else if (bs["alignmentBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "text-align:" + bs["alignmentBody"] + ";";
    } else if (bs["italicBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "font-style:" + (bs["italicBody"] === false ? "normal" : "italic") + ";";
    } else if (bs["boldBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "font-weight:" + (bs["boldBody"] === false ? "normal" : "bold !important") + ";";
    } else if (bs["underlineBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "text-decoration:" + (bs["underlineBody"] === false ? "none" : "underline") + ";";
    }
  }

  const createPhantomStyles = (styleDefinition) => {
    const styleTitle = document.createElement("style");
    let styleHtml = String((".cssTitle_" + props.id + " {" + JSON.stringify(styleDefinition.traditionalStylesTitle) + "}").replaceAll('"', ""));
    styleTitle.innerHTML = styleHtml;
    document.head.appendChild(styleTitle);

    const styleHeader = document.createElement("style");
    styleHtml = String((".cssHeader_" + props.id + " {" + JSON.stringify(styleDefinition.traditionalStylesHeader) + "}").replaceAll('"', ""));
    styleHeader.innerHTML = styleHtml;
    document.head.appendChild(styleHeader);

    const styleBody = document.createElement("style");
    styleHtml = String((".cssBody_" + props.id + " {" + JSON.stringify(styleDefinition.traditionalStylesBody) + "}").replaceAll('"', ""));
    styleBody.innerHTML = styleHtml;
    document.head.appendChild(styleBody);

    initialLoadRef.current = false
    setInitialLoad(false)
  }

  const loadNotifications = async (props) => {
    try {
      const exts = await ExtensionsApi.get(props.extensionSelectedId);
      await FlowNotification.getNotifications(exts.name)
      .then(result => {
        loadRowData(result.data)
        .then()
        .catch(error => {
          console.log(error)
        })
      })
      .catch(error => {
        console.log(error)
      })

      extensionNameRef.current = exts.name;
      setExtensionName(exts.name);
      await establishConnections()
    } catch (error) {
      console.log(error);
    }
  };

  const loadRowData = async (requestItems) => {
    console.log("loadRowData called");
    setRowData(requestItems.map(req => ({
      "requestId": req.id,
      "EMAIL_RECIPIENT": req.notificationEmailRecipient,
      "EMAIL_SENDER": req.notificationEmailSender,
      "EMAIL_SENDER_USER": req.notificationUserSender,
      "SUBJECT": req.subject,
      "STATUS": req.status,
      "attachment": req.attachment,
      "DATE": format(new Date(req.notificationDate), "PPp", { locale: enUS })
    })));
  };

  if (props.dataSource === "Extension" && initialLoadRef.current) {
    var requestItems = [
      { EMAIL_RECIPIENT: "Email Recipient" },
      { EMAIL_SENDER: "Email Sender" },
      { EMAIL_SENDER_USER: "Sender Name" },
      { SUBJECT: "Email Subject" },
      { STATUS: "Email Status" },
      { DATE: "Date Sent" },
      { attachment: "" },
    ]

    let tblColumns = [];

    requestItems.forEach(col => {
      if (props.blockStyles !== undefined) {
        if (props.blockStyles.length !== 0) {
          props.blockStyles.forEach((bs) => {
            createTableStyles(styleDefinition, bs)
          });
        }
      }

      if (initialLoadRef.current) {
        createPhantomStyles(styleDefinition)
      }

      for (const [key, value] of Object.entries(col)) {
        var tableColumn = {
          cellClass: "cssBody_" + props.id,
          headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
          field: key,
          headerName: value,
          flex: 1,
          minWidth: 100,
          wrapText: false,
          autoHeight: true,
        }
        if (key === "EMAIL_RECIPIENT") {
          tableColumn.editable = (props) => props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected"
        }
        if (key === "SUBJECT") {
          tableColumn.editable = (props) => props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected"
        }
        if (key === "DATE") {
          tableColumn.cellRenderer = DateRenderer
        }
        if (key === "attachment") {
          tableColumn.cellRenderer = ImageRenderer
        }
        tblColumns.push(tableColumn);
      }
    });

    // column rendered by function
    var tableColumn = {
      cellClass: "cssBody_" + props.id,
      headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
      field: "",
      headerName: "",
      flex: 1,
      minWidth: 100,
      wrapText: true,
      autoHeight: true,
      cellRenderer: CallButtonRenderer,
    }
    tblColumns.push(tableColumn);

    setColumnDefs(tblColumns)
  }

  const loadTable = () => {
    if (props.mapping.length === 0) {
      console.warn("Missing Mappings For TableBlock", props.id);
    }

    let tblColumns = [];
    if (props.mapping !== undefined) {
      props.mapping.forEach((col) => {

        if (props.blockStyles !== undefined) {
          if (props.blockStyles.length !== 0) {
            props.blockStyles.forEach((bs) => {
              createTableStyles(styleDefinition, bs)
            });
          }
        }

        if (initialLoadRef.current) {
          createPhantomStyles(styleDefinition)
        }

        if (col[1].isUrl) {
          tblColumns.push({
            cellClass: "cssBody_" + props.id,
            headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
            field: col[1].columnName,
            flex: 1,
            minWidth: 200,
            wrapText: true,
            autoHeight: true,
            cellRenderer: LinkComponent,
          });
        } else {
          tblColumns.push({
            cellClass: "cssBody_" + props.id,
            headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
            field: col[1].columnName,
            flex: 1,
            minWidth: 200,
            wrapText: true,
            autoHeight: true,
          });
        }
      });
    }

    let tblData = [];
    if (props.mapping !== undefined) {
      props.mapping.forEach((col) => {
        tblData.push({ [col[1].columnName]: col[1].fieldmappath });
        if (col[1].isUrl) {
          tblData.push({ ["hlTitle"]: col[1].hlTitle });
        }
      });
    }

    let blockData = {};
    const blockArr = [];

    if (props.content !== "" && props.content !== undefined) {
      const arrObj = Object.entries(props.content);
      const blockContent = arrObj.find((c) => c[0] === String(props.dataSource));
      if (blockContent !== undefined) {
        blockContent[1].forEach((bc) => {
          tblData.forEach((td) => {
            const curValue = Object.values(td)[0];
            const curKey = Object.keys(td)[0];
            blockData[curKey] = bc[curValue];
          });
          blockArr.push(blockData);
          blockData = {};
        });
      } else {
        console.warn("Invalid mapping for", props.blockName);
      }
    }

    setColumnDefs(tblColumns);

    if (JSON.stringify(columnDefs) !== JSON.stringify(tblColumns)) {
      setColumnDefs(tblColumns);
    }

    if (JSON.stringify(rowData) !== JSON.stringify(blockArr)) {
      setRowData(blockArr);
    }
  }

  const establishConnections = async () => {
    if (extensionNameRef.current !== null) {
      const websocketUrl = `${FlowNotification.responderHubUrl}?extName=${extensionNameRef.current}`;
      const connection = new HubConnectionBuilder()
        .withUrl(websocketUrl, { accessTokenFactory: async () => await authService.getToken() })
        .withAutomaticReconnect()
        .build();

      connection.start()
        .then(() => {
          connection.on("UpdateNotifications", () => {
            loadNotifications(props)
              .then()
              .catch(error => {
                console.log(error)
              });
          });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
  }

  useEffect(() => {
    if (props.dataSource === "Extension") {
      loadNotifications(props);
    }
  }, []);

  useEffect(() => {
    if (props.dataSource !== "Extension") {
      loadTable()
    }
  }, [props.content]);

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  });

  return (
    <div id={props.keyId} className="card my-2">
      <div className={"card-header cssTitle_" + props.id}>{props.blockName}</div>
      <div style={{ height: "100%", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <div style={{ flexGrow: "1" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                className="ag-theme-alpine"
                animateRows="true"
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                rowSelection="multiple"
                suppressRowClickSelection="true"
                domLayout="autoHeight"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
