import React, { useState, useEffect, useRef } from "react";
import { slide as Menu } from "react-burger-menu";
import { hamburgerMenuStyles } from "../../styles/HamburgerMenu";
import { Form, Button, Alert, Container as BSContainer } from "react-bootstrap";
import { MappingInput } from "./MappingInput";
import { ParentDDL } from "./ParentDDL";
import { updateConfigs } from "../../helpers";
import { DataSourceMulti } from "./DataSourceMulti";
import { FileUpload } from "./FileUpload";
import { Spinner } from "../../pages/Spinner"

export const BurgerMenuPane = (props) => {
  const [currentParentDDLs, setCurrentParentDDLs] = useState([]);
  const currentParentDDLsRef = useRef([]);
  currentParentDDLsRef.current = currentParentDDLs;

  const [blockId, setblockID] = useState(0);

  const [mappingInput, setMappingInput] = useState(props.initialMappingInput);
  const mappingInputRef = useRef([]);
  mappingInputRef.current = mappingInput;

  const [mappingInputChanged, setMappingInputChanged] = useState(false);
  const mappingInputChangedRef = useRef(false);
  mappingInputChangedRef.current = mappingInputChanged;

  const [reloadConfigs, setReloadConfigs] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [newConfigs, setNewConfigs] = useState([])

  const formRef = useRef(null)
  const [validated, setValidated] = useState(false)

  function mappingInputHandler(value, isNew = false) {
    let mappingInputTemp = mappingInputRef.current;
    if (isNew) {
      let isFound = false;
      mappingInputTemp.forEach((mit, i) => {
        if (mit.parent_id === value[0].parent_id) {
          mappingInputTemp[i] = value[0];
        }
      });
      if (!isFound) {
        mappingInputTemp.push(value[0]);
      }
    }
    mappingInputTemp.map((mit) => {
      if (value[0].parent_id === mit.parent_id) {
        return value[0];
      } else {
        return mit;
      }
    });

    mappingInputRef.current = mappingInputTemp;
    setMappingInput(mappingInputTemp);

    let newArray = [];
    currentParentDDLsRef.current.forEach((i) => {
      newArray.push(
        <ParentDDL
          blockId={i.props.blockId}
          id={i.props.id}
          key={i.key}
          blockKeyId={i.props.blockKeyId}
          onSelectionOption={i.props.onSelectionOption}
          selected={i.props.selected}
          onRemoveItem={i.props.onRemoveItem}
          content={i.props.content}
          getAdminSettings={i.props.getAdminSettings}
          mappingInput={mappingInputTemp.filter((tmi) => tmi.parent_id === i.props.id)}
          removeMappingInput={i.props.removeMappingInput}
          setMappingInput={i.props.setMappingInput}
          isProd={i.props.isProd}
          initialValuesFromAPI={props.initialValuesFromAPI}
        />
      );
    });

    currentParentDDLsRef.current = newArray;
    setCurrentParentDDLs(newArray);
  }

  useEffect(() => {
    if (mappingInputChangedRef.current) {
      let newArray = [];
      currentParentDDLsRef.current.forEach((i) => {
        newArray.push(
          <ParentDDL
            blockId={i.props.blockId}
            id={i.props.id}
            key={i.key}
            blockKeyId={i.props.blockKeyId}
            onSelectionOption={i.props.onSelectionOption}
            selected={i.props.selected}
            onRemoveItem={i.props.onRemoveItem}
            content={i.props.content}
            getAdminSettings={props.getAdminSettings}
            mappingInput={i.props.mappingInput}
            removeMappingInput={i.props.removeMappingInput}
            setMappingInput={i.props.setMappingInput}
            isProd={i.props.isProd}
            initialValuesFromAPI={props.initialValuesFromAPI}
          />
        );
      });
      mappingInputChangedRef.current = false;
      setMappingInputChanged(false);

      currentParentDDLsRef.current = newArray;
      setCurrentParentDDLs(newArray);
    }
  }, [currentParentDDLs, props.getAdminSettings, mappingInput, mappingInputChanged]);

  const onRemoveItem = async (event) => {
    const parentId = event.currentTarget.offsetParent.id;
    let newArray = [];
    currentParentDDLsRef.current.forEach((i) => {
      if (parentId !== i.props.id) {
        newArray.push(
          <ParentDDL
            blockId={i.props.blockId}
            id={i.props.id}
            key={i.key}
            blockKeyId={i.props.blockKeyId}
            onSelectionOption={i.props.onSelectionOption}
            selected={i.props.selected}
            onRemoveItem={i.props.onRemoveItem}
            content={i.props.content}
            getAdminSettings={i.props.getAdminSettings}
            mappingInput={i.props.mappingInput}
            removeMappingInput={i.props.removeMappingInput}
            setMappingInput={i.props.setMappingInput}
            isProd={i.props.isProd}
            initialValuesFromAPI={props.initialValuesFromAPI}
          />
        );
      }
    });

    // remove the mappingInputs for the removed block
    let tempMappingInput = mappingInput;
    let newMappingInput = tempMappingInput.filter((mi) => mi.parent_id !== parentId);

    mappingInputRef.current = newMappingInput;
    setMappingInput(newMappingInput);

    currentParentDDLsRef.current = newArray;
    setCurrentParentDDLs(newArray);
  };

  const onSelectionOption = async (event) => {
    let newArray = [];

    // clear the selected option inputs
    const selectedOptionId = currentParentDDLsRef.current.find((cpr) => cpr.props.id === event.target.offsetParent.id).props.blockId;
    let tempMappingInput = mappingInputRef.current;

    if (tempMappingInput.find((tmi) => tmi.no === selectedOptionId) !== undefined) {
      tempMappingInput.find((tmi) => tmi.no === selectedOptionId).inputs = [];
    }

    currentParentDDLsRef.current.forEach((i) => {
      newArray.push(
        <ParentDDL
          blockId={i.props.blockId}
          id={i.props.id}
          key={i.key}
          blockKeyId={i.props.blockKeyId}
          onSelectionOption={i.props.onSelectionOption}
          selected={event.target.offsetParent.id === i.props.id ? event.target.value : i.props.selected}
          onRemoveItem={i.props.onRemoveItem}
          content={i.props.content}
          getAdminSettings={props.getAdminSettings}
          mappingInput={i.props.blockId === selectedOptionId ? [] : i.props.mappingInput}
          removeMappingInput={i.props.removeMappingInput}
          setMappingInput={mappingInputHandler}
          isProd={i.props.isProd}
          initialValuesFromAPI={props.initialValuesFromAPI}
        />
      );
    });
    mappingInputRef.current = tempMappingInput;
    setMappingInput(tempMappingInput);

    currentParentDDLsRef.current = newArray;
    setCurrentParentDDLs([...newArray]);
  };

  const onAddItem = () => {
    const newBlockId = blockId + 1;
    const idForParentDDL = newBlockId + "_ParentDDLcard";
    setblockID(newBlockId);

    const newMappingInputObj = { parent_id: idForParentDDL, no: newBlockId, inputs: [] };
    let tempMappingInput = mappingInput;
    tempMappingInput.push(newMappingInputObj);
    mappingInputRef.current = tempMappingInput;
    setMappingInput(tempMappingInput);

    const blockKeyId = String.fromCharCode(newBlockId + 64).toLowerCase();
    const newParentDDL = (
      <ParentDDL
        id={idForParentDDL}
        key={blockKeyId}
        blockId={newBlockId}
        blockKeyId={blockKeyId}
        onSelectionOption={onSelectionOption}
        selected={-1}
        content={""}
        onRemoveItem={onRemoveItem}
        getAdminSettings={props.getAdminSettings}
        mappingInput={tempMappingInput.filter((tmi) => tmi.parent_id === idForParentDDL)}
        removeMappingInput={removeMappingInput}
        setMappingInput={mappingInputHandler}
        isProd={props.isProd}
        initialValuesFromAPI={props.initialValuesFromAPI}
      />
    );
    currentParentDDLs.push(newParentDDL);

    currentParentDDLsRef.current = currentParentDDLs;
    // currentParentDDLs.reverse();
    setCurrentParentDDLs(currentParentDDLs);
  };

  const hitSaveBtn = async (shouldLoadConfigs = false, willFireSpinner = true) => {
    if (formRef.current.checkValidity() === false) {
      setValidated(true);
    } else {
      if (willFireSpinner) {
        setIsLoading(true)
      }

      let blockConfigsToSave = currentParentDDLsRef.current.filter((cpdr) => cpdr.props.selected !== -1);
      let mappingInputsToSave = mappingInputRef.current.filter((mir) => mir.inputs.length !== 0);

      await updateConfigs(props.pageId, blockConfigsToSave, "UIBlockConfigs", props.configId, props.isAdmin);
      await updateConfigs(props.pageId, mappingInputsToSave, "UIMappingInputs", props.configId, props.isAdmin);

      const allBlocks = Array.from(document.querySelectorAll(".childContent"));
      let newArr = [];

      allBlocks.forEach((ab) => {
        let blockType = "";
        if (ab.classList.contains("blockTable")) {
          blockType = "table";
        } else if (ab.classList.contains("blockInformational")) {
          blockType = "informational";
        } else if (ab.classList.contains("blockButtons")) {
          blockType = "buttons";
        } else if (ab.classList.contains("blockFreeForm")) {
          blockType = "freeform";
        } else if (ab.classList.contains("blockSearch")) {
          blockType = "search";
        } else if (ab.classList.contains("blockSpacer")) {
          blockType = "spacer";
        } else if (ab.classList.contains("dataSourceTable")) {
          blockType = "dstab";
        } else if (ab.classList.contains("fileUploadTable")) {
          blockType = "futab";
        }

        const key = ab.querySelector(".blockKey");
        const id = ab.querySelector(".blockId");

        let styleBlockInputs = [];
        const styles = Array.from(ab.querySelectorAll(".styleField"));
        if (styles.length !== 0) {
          styles.forEach((s) => {
            if (s.classList.contains("form-check")) {
              const idArr = s.firstChild.id.split("_");
              styleBlockInputs.push({ [idArr[0]]: s.firstChild.checked });
            } else {
              const idArr = s.id.split("_");
              styleBlockInputs.push({ [idArr[0]]: s.value });
            }
          });
        }

        const selectedDataSource = ab.querySelector(".selectedDataSource");
        const selectedExtension = ab.querySelector(".selectedExtension");
        const dataSource = ab.querySelector(".dataSource");
        let blockName = ab.querySelector(".blockName");
        const mappingInput = ab.querySelector(".mappingInput");
        const mappingFields = Array.from(mappingInput === null ? [] : mappingInput.querySelectorAll(".mappingFields"));
        let inputArr = [];

        mappingFields.forEach((mf) => {
          let blockContainer;
          if (blockType === "table") {
            blockContainer = mf.querySelector("#tableContain");
          } else if (blockType === "informational") {
            blockContainer = mf.querySelector("#informationalContain");
          } else if (blockType === "buttons") {
            blockContainer = mf.querySelector("#buttonContain");
          } else if (blockType === "freeform") {
            if (mf.querySelector("#textContain") !== null) {
              blockContainer = mf.querySelector("#textContain");
            } else if (mf.querySelector("#imageContain") !== null) {
              blockContainer = mf.querySelector("#imageContain");
            }
          } else if (blockType === "search") {
            blockContainer = mf.querySelector("#searchContain");
          } else if (blockType === "dstab") {
            blockContainer = mf.querySelector("#dsContain");
          } else if (blockType === "futab") {
            blockContainer = mf.querySelector("#fuContain");
          } else if (blockType === "spacer") {
            blockContainer = mf.querySelector("#spacerContain");
          }

          let inputObj = {};
          if (blockContainer !== null && blockContainer !== undefined) {
            let textInputs = Array.from(blockContainer.querySelectorAll(".valueField"));
            textInputs.map((ti) => {
              if (ti.classList.contains("form-check")) {
                return (inputObj[ti.firstChild.id] = ti.firstChild.checked);
              }
              return (inputObj[ti.id] = ti.value);
            });
            if (blockContainer.id === "imageContain") {
              const imageUrl = mf.querySelector("#imageUrl");
              const encodedUrl = encodeURI(imageUrl.value);
              inputObj[imageUrl.id] = encodedUrl;
            }
            inputObj["inputType"] = blockContainer.id;
            inputArr.push([mf.id, inputObj]);
          }
        });

        let selectedExtensionId = null
        if (selectedExtension !== null) {
          selectedExtensionId = selectedExtension.value;
        }

        let selectedDataSourceId = null
        if (selectedDataSource !== null) {
          selectedDataSourceId = selectedDataSource.value;
        }

        const obj = {
          id: id.value,
          key: key.value,
          blockType: blockType,
          dataSource:
            blockType === "buttons" ||
              blockType === "freeform" ||
              blockType === "spacer" ||
              blockType === "dstab" ||
              blockType === "futab" ||
              blockType === "search"
              ? null
              : dataSource.value,
          dataSourceSelectedId:
            blockType === "table" ||
              blockType === "informational"
              ? selectedDataSourceId
              : null,
          extensionSelectedId:
            blockType === "table"
              ? selectedExtensionId
              : null,
          blockName:
            blockType === "buttons" ||
              blockType === "freeform" ||
              blockType === "spacer" ||
              blockType === "dstab" ||
              blockType === "futab" ||
              blockType === "search"
              ? null
              : blockName.value,
          inputs: inputArr,
          blockStyles: styleBlockInputs,
        };
        newArr.push(obj);
      });
      props.getAdminSettings(newArr);

      await updateConfigs(props.pageId, newArr, "UIInputValues", props.configId, props.isAdmin)
        .then(() => {
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
        });

      if (shouldLoadConfigs) {
        await props.loadConfigs(true)
          .then((newConfigs) => {
            setReloadConfigs(true)
            setNewConfigs(newConfigs)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      setValidated(false)
    }
  };

  const removeMappingInput = (idToRemove, blockId) => {
    let tempMappingInput = mappingInputRef.current;
    const filteredMappingInput = mappingInputRef.current.find((mi) => mi.no === blockId).inputs.filter((mi) => mi.props.id !== idToRemove);
    tempMappingInput.find((mi) => mi.no === blockId).inputs = filteredMappingInput;

    mappingInputRef.current = tempMappingInput;
    setMappingInput([...tempMappingInput]);

    let tempParentDDLs = currentParentDDLsRef.current;

    tempParentDDLs.map((i) => {
      if (i.props.blockId === blockId) {
        return (
          <ParentDDL
            blockId={i.props.blockId}
            id={i.props.id}
            key={i.key}
            blockKeyId={i.props.blockKeyId}
            onSelectionOption={i.props.onSelectionOption}
            selected={i.props.selected}
            onRemoveItem={i.props.onRemoveItem}
            content={i.props.content}
            getAdminSettings={props.getAdminSettings}
            mappingInput={tempMappingInput.map((tmi) => tmi)}
            removeMappingInput={i.props.removeMappingInput}
            setMappingInput={i.props.setMappingInput}
            isProd={i.props.isProd}
            initialValuesFromAPI={props.initialValuesFromAPI}
          />
        );
      }
      return i;
    });
    currentParentDDLsRef.current = tempParentDDLs;
    setCurrentParentDDLs([...tempParentDDLs]);

    mappingInputChangedRef.current = true;
    setMappingInputChanged(true);
  };

  useEffect(() => {
    if (props.initialLoad || reloadConfigs) {
      let tempMappingInput = [];
      if (props.initialMappingInput.length !== 0) {
        props.initialMappingInput.forEach((mi) => {
          let inputs = [];
          mi.inputs.forEach((i) => {
            let tempInput = (
              <MappingInput
                type={i.props.type}
                key={i.key}
                id={i.props.id}
                blockId={mi.no}
                removeMappingInput={removeMappingInput}
                initialValuesFromAPI={reloadConfigs ? newConfigs : props.initialValuesFromAPI}
                isProd={props.isProd}
              />
            );
            inputs.push(tempInput);
          });
          tempMappingInput.push({ parent_id: mi.parent_id, no: mi.no, type: mi.type, inputs: inputs });
        });
      }
      mappingInputRef.current = tempMappingInput;
      setMappingInput(tempMappingInput);

      if (currentParentDDLsRef.current.length === 0) {
        let initialBlockConfigs = [];
        props.initialBlockConfig.forEach((bc) => {
          initialBlockConfigs.push(
            <ParentDDL
              key={bc.key}
              blockId={bc.props.blockId}
              id={bc.props.id}
              blockKeyId={bc.props.blockKeyId}
              selected={bc.props.selected}
              content={bc.props.content}
              onSelectionOption={onSelectionOption}
              onRemoveItem={onRemoveItem}
              getAdminSettings={props.getAdminSettings}
              mappingInput={mappingInputRef.current.filter((tmi) => tmi.parent_id === bc.props.id)}
              removeMappingInput={removeMappingInput}
              initialValuesFromAPI={reloadConfigs ? newConfigs : props.initialValuesFromAPI}
              setMappingInput={mappingInputHandler}
              isProd={props.isProd}
            />
          );
          currentParentDDLsRef.current = initialBlockConfigs;
          setCurrentParentDDLs(initialBlockConfigs);
        });
      } else {
        let newParentDDLs = currentParentDDLsRef.current.map(bc => {
          return (
            <ParentDDL
              key={bc.key}
              blockId={bc.props.blockId}
              id={bc.props.id}
              blockKeyId={bc.props.blockKeyId}
              selected={bc.props.selected}
              content={bc.props.content}
              onSelectionOption={onSelectionOption}
              onRemoveItem={onRemoveItem}
              getAdminSettings={props.getAdminSettings}
              mappingInput={mappingInputRef.current.filter((tmi) => tmi.parent_id === bc.props.id)}
              removeMappingInput={removeMappingInput}
              initialValuesFromAPI={reloadConfigs ? newConfigs : props.initialValuesFromAPI}
              setMappingInput={mappingInputHandler}
              isProd={props.isProd}
            />
          )
        })
        setCurrentParentDDLs(newParentDDLs)
      }

      let numArr = [];
      props.initialBlockConfig.forEach((ibc) => {
        numArr.push(ibc.props.blockId);
      });
      let newBlockId = 0;
      if (numArr.length !== 0) {
        newBlockId = Math.max(...numArr) + 1;
      }
      setblockID(newBlockId);

      props.setInitialLoad(false);
      setReloadConfigs(false)
    }
  });

  return (
    <Menu right styles={hamburgerMenuStyles} width={"40%"}>
      <BSContainer style={{ height: "100%" }}>
        <div className="pb-2">
          <Button disabled={isLoading} type="button" onClick={() => onAddItem(this)} className="btn-secondary" style={{ marginRight: "1px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </Button>
          <Button disabled={isLoading} variant="primary" onClick={() => hitSaveBtn(this)}>
            Publish
          </Button>
        </div>
        {isLoading ? <div style={{ textAlign: "center", top: "50%", position: "relative" }}><Spinner width={96} isActive={true} /></div> : <></>}
        <div className={isLoading ? "d-none" : ""}>
          {validated === true ? <Alert key='danger' variant='danger'>Correct errors found below.</Alert> : <></>}
          <Form ref={formRef} validated={validated} noValidate>
            {currentParentDDLsRef.current.map((parentDDL) => parentDDL)}
          </Form>
          <DataSourceMulti hitSaveBtn={hitSaveBtn} initialValuesFromAPI={props.initialValuesFromAPI} setContentHandler={props.setContentHandler} isProd={props.isProd} />
          <FileUpload hitSaveBtn={hitSaveBtn} isProd={props.isProd} initialValuesFromAPI={props.initialValuesFromAPI} />
        </div>
      </BSContainer>
    </Menu>
  );
};
