import React, { useEffect, useState } from "react";

export const InformationalBlock = (props) => {
  const [backgroundColorTitle, setBackgroundColorTitle] = useState("");
  const [fontSizeTitle, setFontSizeTitle] = useState("");
  const [fontColorTitle, setFontColorTitle] = useState("");
  const [fontFamilyTitle, setFontFamilyTitle] = useState("");
  const [justificationTitle, setJustificationTitle] = useState("");
  const [italicTitle, setItalicTitle] = useState("");
  const [boldTitle, setBoldTitle] = useState("");
  const [underlineTitle, setUnderlineTitle] = useState("");

  const [fontSizeLabel, setFontSizeLabel] = useState("");
  const [fontColorLabel, setFontColorLabel] = useState("");
  const [fontFamilyLabel, setFontFamilyLabel] = useState("");
  const [justificationLabel, setJustificationLabel] = useState("");
  const [italicLabel, setItalicLabel] = useState("");
  const [boldLabel, setBoldLabel] = useState("");
  const [underlineLabel, setUnderlineLabel] = useState("");

  const [fontSizeValue, setFontSizeValue] = useState("");
  const [fontColorValue, setFontColorValue] = useState("");
  const [fontFamilyValue, setFontFamilyValue] = useState("");
  const [justificationValue, setJustificationValue] = useState("");
  const [italicValue, setItalicValue] = useState("");
  const [boldValue, setBoldValue] = useState("");
  const [underlineValue, setUnderlineValue] = useState("");

  if (props.mapping.length === 0) {
    console.warn("Missing Mappings For InformationalBlock", props.id);
  }

  useEffect(() => {
    if (props.blockStyles !== undefined) {
      props.blockStyles.forEach((bs) => {
        if (bs["bgColorTitle"] !== undefined) {
          setBackgroundColorTitle(bs["bgColorTitle"]);
        } else if (bs["fontSizeInputTitle"] !== undefined) {
          setFontSizeTitle(bs["fontSizeInputTitle"]);
        } else if (bs["fontColorInputTitle"] !== undefined) {
          setFontColorTitle(bs["fontColorInputTitle"]);
        } else if (bs["fontFamilyInputTitle"] !== undefined) {
          setFontFamilyTitle(bs["fontFamilyInputTitle"]);
        } else if (bs["alignmentTitle"] !== undefined) {
          setJustificationTitle(bs["alignmentTitle"]);
        } else if (bs["italicTitle"] !== undefined) {
          setItalicTitle(bs["italicTitle"]);
        } else if (bs["boldTitle"] !== undefined) {
          setBoldTitle(bs["boldTitle"]);
        } else if (bs["underlineTitle"] !== undefined) {
          setUnderlineTitle(bs["underlineTitle"]);
        } else if (bs["fontColorInputHeader"] !== undefined) {
          setFontColorLabel(bs["fontColorInputHeader"]);
        } else if (bs["fontSizeInputHeader"] !== undefined) {
          setFontSizeLabel(bs["fontSizeInputHeader"]);
        } else if (bs["fontFamilyInputHeader"] !== undefined) {
          setFontFamilyLabel(bs["fontFamilyInputHeader"]);
        } else if (bs["alignmentHeader"] !== undefined) {
          setJustificationLabel(bs["alignmentHeader"]);
        } else if (bs["italicHeader"] !== undefined) {
          setItalicLabel(bs["italicHeader"]);
        } else if (bs["boldHeader"] !== undefined) {
          setBoldLabel(bs["boldHeader"]);
        } else if (bs["underlineHeader"] !== undefined) {
          setUnderlineLabel(bs["underlineHeader"]);
        } else if (bs["fontColorInputBody"] !== undefined) {
          setFontColorValue(bs["fontColorInputBody"]);
        } else if (bs["fontSizeInputBody"] !== undefined) {
          setFontSizeValue(bs["fontSizeInputBody"]);
        } else if (bs["fontFamilyInputBody"] !== undefined) {
          setFontFamilyValue(bs["fontFamilyInputBody"]);
        } else if (bs["alignmentBody"] !== undefined) {
          setJustificationValue(bs["alignmentBody"]);
        } else if (bs["italicBody"] !== undefined) {
          setItalicValue(bs["italicBody"]);
        } else if (bs["boldBody"] !== undefined) {
          setBoldValue(bs["boldBody"]);
        } else if (bs["underlineBody"] !== undefined) {
          setUnderlineValue(bs["underlineBody"]);
        }
      });
    }
  }, [props.blockStyles]);

  let tblData = [];
  props.mapping.forEach((col) => {
    tblData.push({ [col[1].fieldName]: col[1].fieldmappath });
  });

  let blockData = {};
  const blockArr = [];

  if (props.content !== "" && props.content !== undefined) {
    const arrObj = Object.entries(props.content);
    const blockContent = arrObj.find((c) => c[0] === String(props.dataSource));
    if (blockContent !== undefined) {
      tblData.forEach((td) => {
        const curValue = Object.values(td)[0];
        const curKey = Object.keys(td)[0];
        blockData[curKey] = blockContent[1][curValue];
        blockArr.push(blockData);
        blockData = {};
      });
    } else {
      console.warn("Invalid mapping for", props.blockName);
    }
  }

  let details = blockArr.length === 0 ? props.mapping : blockArr;

  let contentToDisplay = [];
  let childElements = [];

  let labelStyle = {
    fontSize: fontSizeLabel,
    color: fontColorLabel,
    fontFamily: fontFamilyLabel,
    textAlign: justificationLabel,
    fontStyle: italicLabel ? "italic" : "none",
    fontWeight: boldLabel ? "bold" : "none",
    textDecoration: underlineLabel ? "underline" : "none",
  }

  let valueStyle = {
    fontSize: fontSizeValue,
    color: fontColorValue,
    fontFamily: fontFamilyValue,
    textAlign: justificationValue,
    fontStyle: italicValue ? "italic" : "none",
    fontWeight: boldValue ? "bold" : "none",
    textDecoration: underlineValue ? "underline" : "none",
  }

  details.forEach((detail, index) => {
    const inx = index + 1;

    let content;
    if (blockArr.length !== 0) {
      let value = Object.values(detail)[0];
      if (value !== undefined) {
        if (value.includes(".jpg") || value.includes(".png") || value.includes(".jpeg")) {
          content = <img style={{ maxWidth: "100%", height: "auto" }} src={value} />;
        } else if (value.includes("://")) {
          let url;
          try {
            url = new URL(value);
          } catch (ex) {
            console.warn("Invalid URL for informational", value, ex);
            url = value;
          }
          content = (
            <a href={url} rel="noopener noreferrer" target="_blank">
              {value}
            </a>
          );
        } else {
          content = value;
        }
      }
    }

    childElements.push(
      <div key={inx + "_child"} className="row">
        <p style={labelStyle}>{blockArr.length !== 0 ? Object.keys(detail)[0] : detail.name}</p>
        <p style={valueStyle}>{blockArr.length !== 0 ? content : ""}</p>
      </div>
    );
    if (inx % 2 === 0) {
      contentToDisplay.push(
        <div key={inx + "_parent"} className="col py-2">
          {childElements}
        </div>
      );
      childElements = [];
    } else if (inx === blockArr.length) {
      contentToDisplay.push(
        <div key={inx + "_parent"} className="col py-2">
          {childElements}
        </div>
      );
      childElements = [];
    }
  });

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  });

  return (
    <div id={props.keyId} className="card mt-2">
      <div
        className="card-header"
        style={{
          backgroundColor: backgroundColorTitle,
          fontSize: fontSizeTitle,
          color: fontColorTitle,
          fontFamily: fontFamilyTitle,
          textAlign: justificationTitle,
          fontStyle: italicTitle ? "italic" : "none",
          fontWeight: boldTitle ? "bold" : "none",
          textDecoration: underlineTitle ? "underline" : "none",
        }}
      >
        {props.blockName}
      </div>
      <div className="container">
        <div className="row">{contentToDisplay}</div>
      </div>
    </div>
  );
};
